import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import {HTMLContent} from '../components/Content'

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      <div key={item.text} className="column is-6">
        <section className="section">
          <div className="has-text-centered">
            <div
              style={{
                width: '240px',
                display: 'inline-block',
              }}
            >
              <PreviewCompatibleImage imageInfo={item} style={{borderRadius: '50%'}} />
            </div>
          </div>
          <div>
            {item.subject && <p style={{textAlign: "center", color: "#CC3700", fontWeight: "bold"}}>{item.subject}</p>}
            {item.title && <p style={{textAlign: "center"}}><em><small>{item.title}</small></em></p>}
            {item.name && <p style={{textAlign: "center"}}><strong>{item.name}</strong></p>}
            <HTMLContent className="content has-text-centered" content={item.text}></HTMLContent>
          </div>
        </section>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
